import { useState } from "react";
import styled from 'styled-components';

export const ScalePickerControlled = ({ items = ["Excellent", "Very Good", "Good", "Fair", "Poor"], verticalPx = 900, sel = 1, pickCallback }) => {
  /* const [selected, setSelected] = useState(sel); */
  return (
    <SPBox verticalPx={verticalPx}>
      {items.map((item, index) => (
        <SPItem className="spitem" onClick={(e) => { pickCallback(e, index); }} sel={sel === index}>{item}</SPItem>
      ))}
    </SPBox>
  );
}

export const ScalePicker = ({ items = ["Excellent", "Very Good", "Good", "Fair", "Poor"], verticalPx = 900, sel = 1/* , pickCallback */ }) => {
  const [selected, setSelected] = useState(0);
  const pickCallback = (index) => setSelected(index);
  return (
    <SPBox verticalPx={verticalPx}>
      {items.map((item, index) => (
        <SPItem className="spitem" onClick={() => { pickCallback(index); }} sel={selected === index}>{item}</SPItem>
      ))}
    </SPBox>
  );
}

const SPItem = styled.div`
  position: relative;
  font-size: calc(1.0 * (13px + 0.3vw));
  font-family: 'Montserrat', sans-serif;
  transition: all 0.2s;
  color: ${props => props.sel !== true ? "#000000" : "#ffffff"};
  background-color: ${props => props.sel !== true ? "#ffffff" : "#143F90"};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  cursor: pointer;
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      color: #ffffff;
      background-color: #143F90;
    }
  }
  @media only screen and (min-width: 1441px) {
    font-size: 18px;
  }
`;

const SPBox = styled.div`
  position: relative;
  box-shadow: 3px 3px 10px #00000090;
  max-width: 800px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: calc(2.0 * (14px + 1.0vw));
  overflow: hidden;
  .spitem {
    border-width: 0px 1px 0px 0px;
    &:last-child{border-width: 0;}
    padding: calc(0.6 * (14px + 1.0vw)) 0;
    width: calc(8.0 * (14px + 0.4vw));
  }
  /* @media only screen and (max-width: ${props => props.verticalPx + "px"}) {
    flex-flow: column nowrap;
    border-radius: calc(1.4 * (14px + 1.0vw));
    .spitem {
      border-width: 0px 0px 1px 0px;
      &:last-child{border-width: 0;}
      padding: calc(1 * (14px + 1.0vw)) calc(2.0 * (14px + 1.0vw));
      width: calc(12.0 * (14px + 0.4vw));
    }
  } */
  @media only screen and (min-width: 1441px) {
    border-radius: calc(2.0 * (28px));
    .spitem {
      padding: calc(0.6 * (28px)) 0;
      width: calc(8.0 * (20px));
    }
  }
`;
