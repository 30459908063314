import { ndia2Eng } from "data/NaturalDiamondShortEng";
import { cgem2Eng } from "data/ColorGemstoneShortEng";

const templateDb = [
  {
    id: 0,
    title: "NATURAL DIAMOND",
    template: ndia2Eng,
    icon: "single-cless-100-0.png"
  },
  {
    id: 1,
    title: "COLOR GEMSTONE",
    template: cgem2Eng,
    icon: "single-color-100-0.png"
  },
];

export default templateDb;
