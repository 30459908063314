const itemTypes = [
  "txt", "scale", "img", "inclusionMap"
];

/* Natural Diamond Short English Report */
export const ndia2Eng = {
  title: "Natural Diamond Short English Report",
  pages: [
    {
      num: 1,
      blocks: [
        {
          title: "",
          yPerc: 2,
          items: [
            { name: "Header", type: "img", img: "header-f1-l0", imgWidthPerc: 90 }
          ]
        },
        {
          title: "NATURAL DIAMOND GRADING REPORT",
          yPerc: 28,
          items: [
            { name: "Date", type: "txt", defValue: "1 January 2021" },
            { name: "Report Number", type: "txt", defValue: "1023029feffefe239r" },
            { name: "Shape and Cutting Style", type: "txt", defValue: "Round Brilliant" },
            { name: "Measurements", type: "txt", defValue: "0.0 - 1.0 x 0.0 mm" }
          ]
        },
        {
          title: "GRADING RESULTS",
          yPerc: 50,
          items: [
            { name: "Carat", type: "txt", defValue: "0.0 ct" },
            { name: "Clarity", type: "scale", /* scaleValues: ["Excellent", "Very Good", "Good", "Fair", "Poor"] */scaleValues: ["F", "IF", "VVS1", "VVS2", "VS1", "VS2", "SI1", "SI2", "I1", "I2", "I3"], defValue: "0" },
            { name: "Color", type: "scale", scaleValues: ["Colorless", "Near Colorless", "Faint", "Very Light", "Light"], defValue: "0" },
            { name: "Cut", type: "scale", scaleValues: ["Excellent", "Very Good", "Good", "Fair", "Poor"], defValue: "0" }
          ]
        },
        {
          title: "ADDITIONAL GRADING INFORMATION",
          yPerc: 70,
          items: [
            { name: "Symmetry", type: "scale", scaleValues: ["Excellent", "Very Good", "Good", "Fair", "Poor"], defValue: "0" },
            { name: "Polish", type: "scale", scaleValues: ["Excellent", "Very Good", "Good", "Fair", "Poor"], defValue: "0" },
            { name: "Fluorescence", type: "scale", scaleValues: ["Excellent", "Very Good", "Good", "Fair", "Poor"], defValue: "0" }
          ]
        }
      ]
    },
    {
      num: 2, blocks: [
        {
          title: "CLARITY CHARACTERISTICS",
          p: 2, yPerc: 15,
          items: [
            { name: "", type: "inclusionMap", inclusions: [] },
            { name: "Scales Image", type: "img", img: "scales-f1-l0", imgWidthPerc: 80 }
          ]
        },
        {
          title: "",
          p: 1, yPerc: 80,
          items: [
            { name: "Footer", type: "img", img: "header-f1-l0", imgWidthPerc: 100 }
          ]
        }
      ]
    }
  ]
};
