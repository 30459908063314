import { frame2ScaledPageHPadding } from "data/Config";
import styled from "styled-components";

const StaticImgItem = ({ title = "image item", imgSrc, y }) => {
  return (
    <SIIBox y={y}>
      <img src={imgSrc} alt={title} />
    </SIIBox>
  );
}

const SIIBox = styled.div`
  position: absolute;
  top: ${props => props.y + "%"};
  left: 0;
  width: 100%;
  padding: ${"0 " + frame2ScaledPageHPadding};
  img {
    position: relative;
    width: 100%;
    height: auto;
  }
`;

export default StaticImgItem;
