import styled from 'styled-components';
import { BlueInvertCircleRippleButton, GreenInvertCircleRippleButton, GreenInvertCircleRippleButton2 } from 'comps/RippleContainer';
/* import { HashLink } from "comps/basic/HashLink"; */
import debounce from "lodash.debounce";
/* import { Sizes } from "data/Basic"; */
/* import debounce from 'lodash.debounce'; */

const FAB = ({ isPreview = false, waModalShow, fabCta }) => {
  const dbcta = debounce(fabCta, 10);
  return (
    <FABMenuBox id="fab-id" up={false}>
      {/* <HashLink to="/#contatti"> */}
      <GreenInvertCircleRippleButton click={dbcta/* waModalShow */} iconSize={1.25} iconPadding="0 0 2px 2px" iconFAClass={isPreview === true ? "fas fa-eye-slash" : "fas fa-eye"} rippleColor={"#DADFF190"} darkColor="#293970f0" effect="circle-darken" className="fabutton" />{/* </HashLink> */}
      <BlueInvertCircleRippleButton /* click={dbcta} */ iconSize={1.25} iconPadding="0 0 2px 2px" iconFAClass="fas fa-file-import" effect="circle-darken" className="fabutton" />{/* </HashLink> */}
    </FABMenuBox>
  );
}

const FABMenuBox = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  /* margin: calc(14px + 0.6vw); */
  &>div {
  margin: calc(1.0 * (14px + 0.5vw)) calc(0.6 * (14px + 0.5vw));
  }
  z-index: 100;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;
  /* transform: ${props => (props.fabUp === true) ? "translateY(-" + "120px" + ")" : "none"}; */
  /* @media only screen and (max-width: 1000px) {
    transform: ${props => (props.fabUp === true) ? "translateY(-" + "300px" + ")" : "none"};
  } */
  @media only screen and (min-width: 1441px) {
    right: calc((100% - 1440px) / 2.0);
  }
  .fabutton {
    /* margin-left: calc(14px + 0.4vw); */
    transform: translateY(-400px);
    opacity: 0.0;
    animation: fabuttonappear 0.3s ease-out 0.0s 1 forwards;
    @keyframes fabuttonappear {
      0% { transform: translateY(-600px); opacity: 0.0;  }
      50% { opacity: 0.25;  }
      100% { transform: translateY(0px); opacity: 1.0;  }
    }
  }
`;

export default FAB;
