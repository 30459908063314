import ImageItem from "comps/ImageItem";
import InclusionMap from "comps/InclusionMap";
import ScaleGrade from "comps/ScaleGrade";
import { frame3r } from "data/Config";
import styled from 'styled-components';

const Frame3 = () => {
  return (
    <Frame3Box>
      <Frame3Page>
        <Frame3Item>
          <ImageItem imgSrc="header-f0-l0" />
        </Frame3Item>
        <Frame3Item>
          <ScaleGrade />
        </Frame3Item>
      </Frame3Page>
      <Frame3Page>
        <Frame3Item>
          <InclusionMap />
        </Frame3Item>
        <Frame3Item>
          <ImageItem imgSrc="scales-f0-l0" />
        </Frame3Item>
      </Frame3Page>
      <Frame3Page>
      </Frame3Page>
    </Frame3Box>
  );
}

const Frame3Box = styled.div`
  position: relative;
  height: 92vh;
  width: ${"calc(92vh / " + frame3r + ")"};
  background-color: #ffffff;
  background-image: linear-gradient(to right, #00000000 0%, #00000000 17%, #00000070 33%, #00000000 33%, #00000000 50%, #00000070 66%, #00000000 66%, #00000000 83%, #00000070 100%);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
`;

const Frame3Page = styled.div`
  position: relative;
  height: 100%;
  width: 33%;
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
`;

const Frame3Item = styled.div`
  position: relative;
  padding: 100px 50px;
`;

export default Frame3;
