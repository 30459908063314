import { useState } from "react";
import styled from 'styled-components';

export const ToggleSmart = ({ selected = false, printed = false, t1 = "News", t2 = "Articoli", t3 = "Articoli", colorPrimary = "#3E56A9" }) => {
  const [t, setT] = useState(printed === true ? 2 : (selected === false ? 1 : 0));
  const toggle = () => setT(p => (p + 1) % 3);
  /* const [t, setT] = useState(selected);
  const toggle = () => setT(p => !p);
  const [tt, setTT] = useState(selected);
  const toggleT = () => setTTT(p => !p); */
  return (
    <TBox className="togglebox" selected={t} colorPrimary={colorPrimary} onClick={toggle}>
      <TT11 colorPrimary={colorPrimary} selected={t === 0}><p>{t1}</p></TT11>
      <TT12 colorPrimary={colorPrimary} selected={t === 1}><p>{t2}</p></TT12>
      <TT13 colorPrimary={colorPrimary} selected={t === 2}><p>{t3}</p></TT13>
    </TBox>
  );
}

export const ToggleControlled = ({ selected = false, onToggle, t1 = "News", t2 = "Articoli", colorPrimary = "#3E56A9" }) => {
  const toggle = () => { onToggle(); };
  return (
    <TBox className="togglebox" colorPrimary={colorPrimary} onClick={toggle}>
      <TT1 colorPrimary={colorPrimary} selected={selected === true}><p>{t1}</p></TT1>
      <TT1 colorPrimary={colorPrimary} selected={selected === false}><p>{t2}</p></TT1>
    </TBox>
  );
}

const TBox = styled.div`
  position: relative;
  padding: 0;
  width: auto;
  display: inline-block;
  border-radius: 999px;
  overflow: hidden;
  /* background-color: ${props => props.colorPrimary + "a0"}; */
  background: ${props => props.selected === 0 ? "#c00000" : (props.selected === 1 ? "#00c000" : "#4040c0")};
  transition: all 0.3s;
  box-shadow: 2px 2px 8px #00000080;
  border: 3px solid #293970ff;
  border-width: 0 1px 3px 0;
  cursor: pointer;
  /* &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #00000000 85%, #00000010, #00000030);
  } */
`;

const TT1 = styled.div`
  position: relative;
  display: inline-block;
  padding: calc(15px + 0.4vw) calc(32px + 0.8vw) calc(15px + 0.4vw) calc(42px + 0.8vw);
  color: #ffffffe0;
  font-size: calc(12px + 0.2vw);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  transition: all 0.25s;
  /* background-color: ${props => props.selected === true ? props.colorPrimary + "ff" : props.colorPrimary + "d0"}; */
  /* background-color: ${props => props.selected === true ? "red" : "green"}; */
  p{position: relative;}
  p::before{
    position: absolute;
    content:"";
    width: 10px;
    height: 10px;
    border-radius: 5px;
    top: calc(50% - 5px);
    left: 0;
    transition: all 0.25s;
    transform: translateX(-200%);
    background-color: ${props => props.selected === true ? "#F0D162ff" : "#F0D16200"};
  }
`;


const TT11 = styled.div`
  position: relative;
  display: inline-block;
  padding: calc(15px + 0.4vw) calc(32px + 0.8vw) calc(15px + 0.4vw) calc(42px + 0.8vw);
  color: #ffffffe0;
  font-size: calc(12px + 0.2vw);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  transition: all 0.25s;
  /* background-color: ${props => props.selected === true ? props.colorPrimary + "ff" : props.colorPrimary + "d0"}; */
  /* background-color: ${props => props.selected === true ? "red" : "green"}; */
  p{position: relative;}
  p::before{
    position: absolute;
    content:"";
    width: 10px;
    height: 10px;
    border-radius: 5px;
    top: calc(50% - 5px);
    left: 0;
    transition: all 0.25s;
    transform: translateX(-200%);
    background-color: ${props => props.selected === true ? "#F0D162ff" : "#F0D16200"};
  }
`;


const TT12 = styled.div`
  position: relative;
  display: inline-block;
  padding: calc(15px + 0.4vw) calc(32px + 0.8vw) calc(15px + 0.4vw) calc(42px + 0.8vw);
  color: #ffffffe0;
  font-size: calc(12px + 0.2vw);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  transition: all 0.25s;
  /* background-color: ${props => props.selected === true ? props.colorPrimary + "ff" : props.colorPrimary + "d0"}; */
  background-color: #00000020;
  p{position: relative;}
  p::before{
    position: absolute;
    content:"";
    width: 10px;
    height: 10px;
    border-radius: 5px;
    top: calc(50% - 5px);
    left: 0;
    transition: all 0.25s;
    transform: translateX(-200%);
    background-color: ${props => props.selected === true ? "#F0D162ff" : "#F0D16200"};
  }
`;

const TT13 = styled.div`
  position: relative;
  display: inline-block;
  padding: calc(15px + 0.4vw) calc(32px + 0.8vw) calc(15px + 0.4vw) calc(42px + 0.8vw);
  color: #ffffffe0;
  font-size: calc(12px + 0.2vw);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  transition: all 0.25s;
  /* background-color: ${props => props.selected === true ? props.colorPrimary + "ff" : props.colorPrimary + "d0"}; */
  background-color: #00000050;
  p{position: relative;}
  p::before{
    position: absolute;
    content:"";
    width: 10px;
    height: 10px;
    border-radius: 5px;
    top: calc(50% - 5px);
    left: 0;
    transition: all 0.25s;
    transform: translateX(-200%);
    background-color: ${props => props.selected === true ? "#F0D162ff" : "#F0D16200"};
  }
`;
