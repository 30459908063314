import styled, { css } from 'styled-components';
import imgInclusionMap from 'img/more/inclmap0.png';
import { useState } from "react";
import { useDebouncedValue } from "Utils";

const ProportionsMapModal = () => {
  const [tableSizePerc, setTableSizePerc] = useState(55.3);
  const [crownHeightPerc, setCrownHeightPerc] = useState(16.2);
  const [pavillionDepthPerc, setPavillionDepthPerc] = useState(43.1);

  return (
    <Over0>
      <PMBox>
        <PMDiamond />
      </PMBox>
    </Over0>
  );
}
const PMDiamond = () => {
  const [tableSizePerc, setTableSizePerc] = useState(55.3);
  const [crownHeightPerc, setCrownHeightPerc] = useState(16.2);
  const [pavillionDepthPerc, setPavillionDepthPerc] = useState(43.1);

  const [height, setHeight] = useState(5);
  /* const debouncedHeight = useDebouncedValue(height, 400); */

  const onChange = event => setTableSizePerc(Math.min(event.target.value, 99.9));

  return (
    <PMBDBox>
      <PMBDTable tableSizePerc={tableSizePerc} />
      <PMBDDiameter crownHeightPerc={crownHeightPerc} />
      <input
        type="number"
        value={tableSizePerc}
        onChange={onChange}
      />
    </PMBDBox>
  );
}

const PMBDDiameter = styled.div`
  position: absolute;
  top: ${props => "calc(" + props.crownHeightPerc + "%)"};
  left: 50%;
  width: 100%;
  height: 4px;
  background-color: #000000;
  transform: translate(-50%, 0);
`;

const PMBDTable = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: ${props => "calc(" + props.tableSizePerc + "%)"};
  height: 4px;
  background-color: #000000;
  transform: translate(-50%, 0);
`;

const PMBDBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(135deg, #00000000, #00000060);
  input {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;

const PMBox = styled.div`
  position: relative;
  width: 70vh;
  height: 70vh;
  background-color: #ff00ff30;
`;

const Over0 = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  padding: calc(1.0 * (14px + 1.0vw));
  box-shadow: 3px 3px 6px #000000b0;
  border-radius: calc(1.0 * (14px + 1.0vw));
  background-color: #ffffffff;
  z-index: 100;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ProportionsMapModal;
