import React, { useState } from 'react';
import styled from 'styled-components';
import { HashLink } from "comps/HashLink";
import { menuItems00 } from 'data/MenuItems';

const EMBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  &::after {
    position: absolute;
    top: 0px;
    left: 5%;
    width: 90%;
    height: 2px;
    content: "";
    background-image: linear-gradient(to right, #ffffff00, #ffffff38, #ffffff38, #ffffff38, #ffffff00);
  }
`;

// multiExpanded controls whether multiple items can be expanded at the same time
const ExpandableMenu = ({ menuItems = menuItems00, multiExpand = false }) => {
  const [expandedItemIndex, setExpandedItemIndex] = useState(-1);

  return (
    <EMBox>
      {
        menuItems.map((item, index) =>
          multiExpand === true ?
            <ExpandableItems items0={item} key={"menuItem" + index} />
            : <ExpandableItemsSingleChoice expandCallback={setExpandedItemIndex} key={"menuItem" + index} itemIndex={index} expandedItemIndex={expandedItemIndex} items0={item} />)
      }
    </EMBox>
  );
}

const ExpandableItemsSingleChoice = ({ items0 = menuItems00[0], itemIndex, expandCallback, expandedItemIndex }) => {
  const toggle = () => expandCallback(expandedItemIndex !== itemIndex ? itemIndex : -1);
  const expanded = (expandedItemIndex === itemIndex);

  return (
    items0.parent === true ?
      <EBox expanded={expanded}>
        <EIcon onClick={toggle} className={expanded === true ? "fas fa-minus" : "fas fa-plus"} />
        <ETitle className="menuitem" onClick={toggle}>{items0.title}</ETitle>
        <EItems itemNumber={items0.children.length} expanded={expanded}>
          {
            items0.children.map((item, index) =>
              <HashLink key={items0.title + "menuSubItem" + index} smooth to={item.link}>
                <EItem itemNumber={index + 1} expanded={expanded}>{item.title}</EItem>
              </HashLink>
            )
          }
        </EItems>
      </EBox> : <EBox><HashLink smooth to={items0.link}><ETitle className="menuitem">{items0.title}</ETitle></HashLink></EBox>

  );
}

const ExpandableItems = ({ items0 = menuItems00[0] }) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => { setExpanded(!expanded); }

  return (
    items0.parent === true ?
      <EBox expanded={expanded}>
        <EIcon onClick={toggle} className={expanded === true ? "fas fa-minus" : "fas fa-plus"} />
        <ETitle onClick={toggle}>{items0.title}</ETitle>
        <EItems itemNumber={items0.children.length} expanded={expanded}>
          {
            items0.children.map((item, index) =>
              <HashLink key={items0.title + "menuSubItem" + index} smooth to={item.link}>
                <EItem itemNumber={index + 1} expanded={expanded}>{item.title}</EItem>
              </HashLink>
            )
          }
        </EItems>
      </EBox> : <EBox><HashLink smooth to={items0.link}><ETitle>{items0.title}</ETitle></HashLink></EBox>
  );
}

const eItemHeightCalcStr = "34px + 0.3vw";
const eItemFontCalcStr = "13px + 0.4vw";
const eItemHalfHeightCalcStr = "17px + 0.15vw";
const eIconFontCalcStr = "10px + 0.2vw";
const eSubItemFontCalcStr = "12px + 0.3vw";

const EBox = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  a { width: 100%; }
  margin: calc(0.4 * (14px + 1.0vw)) 0;
`;

const EIcon = styled.i`
  color: #f0f0f0;
  position: absolute;
  top: ${"calc(" + eItemHalfHeightCalcStr + ")"};
  right: ${"calc(" + eItemHalfHeightCalcStr + ")"};
  transform: translate(25%, -50%);
  font-size: ${"calc(" + eIconFontCalcStr + ")"};
`;

const EItems = styled.div`
  position: relative;
  transition: all 0.25s ease;
  height: ${props => (props.expanded === true) ? ("calc(" + props.itemNumber + " * (" + eItemHeightCalcStr + "))") : "0vh"};
  overflow: hidden;
  width: 100%;
    /* margin: calc(0.2 * (14px + 1.0vw)) 0; */
`;

const EItemBase = styled.p`
  position: relative;
  font-weight: 500;
  font-size: ${"calc(" + eItemFontCalcStr + ")"};
  height: ${"calc(" + eItemHeightCalcStr + ")"};
  line-height: ${"calc(" + eItemHeightCalcStr + ")"};
  cursor: pointer;
  user-select: none;
  transition: all 0.25s;
  color: #e8e8e8;
  width: 100%;
  /* &::first-letter {color: #30a2ff;} */
  @media(hover: hover) and (pointer: fine) { &:hover { &::first-letter {color: #30a2ff;} color: #30a2ff; } }
`;

const ETitle = styled(EItemBase)``;

const EItem = styled(EItemBase)`
  font-size: ${"calc(" + eSubItemFontCalcStr + ")"};
  font-weight: 400;
  transition: all ${props => (props.itemNumber * 0.15)}s ease;
  transform: translateY(${props => props.expanded === true ? "0%" : "-150%"});
`;

export default ExpandableMenu;
