import FAB from "comps/FAB";
import { useState } from "react";
import { useKey } from "react-use";
import ReportRenderForm from "ReportRenderForm";
import ReportRenderPreview from "ReportRenderPreview";
import styled from 'styled-components';
import TemplatePicker from "comps/TemplatePicker";

const ReProTemplatePicker = styled.div`
  position: relative;
  width: 100%;
`;

const RePro = ({ report, onReportChange, openReportIndex }) => {
  const [currReportIndex, setCurrReportIndex] = useState(openReportIndex);

  const [preview, setPreview] = useState(false);
  const togglePreview = () => setPreview(p => !p);
  useKey("g", togglePreview);

  return (
    <RPBox onClick={() => onReportChange({ ...report, /* title: "REPORT CHANGE", */ template: { ...(report.template), template: { ...(report.template.template) } } }, openReportIndex)}>
      <RPPreview show={preview}>
        <ReportRenderPreview report={report} reportIndex={openReportIndex} />
      </RPPreview>
      {/* <ReProTemplatePicker>
        <TemplatePicker templIndex={currReportIndex} pickCallback={setCurrReportIndex} />
      </ReProTemplatePicker> */}
      <RPForm>
        <ReportRenderForm report={report} reportIndex={openReportIndex} />
      </RPForm>
      <FAB /* fabCta={modalShow}  */ fabCta={togglePreview} isPreview={preview} fabUp={false} />
    </RPBox>
  );
}

const RPBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const RPForm = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(2.0 * (14px + 1.0vw));
`;

const RPPreview = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  transition: all 0.5s;
  transform: ${props => props.show ? "translate(0%, 0%)" : "translate(-150%, 0%)"};
  opacity: ${props => props.show ? "1.0" : "0.0"};
  background-color:  ${props => props.show ? "#000000e0" : "#00000000"};
  z-index: 10;
`;

export default RePro;
