import styled from 'styled-components';

import ScrollContainer from "react-indiana-drag-scroll";

import iconBatchColor from 'img/icons/batch-color-100-0.png';
import iconBatchCless from 'img/icons/batch-cless-100-0.png';
import iconSingleColor from 'img/icons/single-color-100-0.png';
import iconSingleCless from 'img/icons/single-cless-100-0.png';

import iconRepFullEng from 'img/icons/rep-full-eng-0.png';
import iconRepFullIta from 'img/icons/rep-full-ita-0.png';
import iconRepShortEng from 'img/icons/rep-short-eng-0.png';
import iconRepShortIta from 'img/icons/rep-short-ita-0.png';
import iconRepFullWDG from 'img/icons/rep-full-wdg-0.png';
import { useState } from "react";


const TemplatePicker = ({ templIndex = -1, pickCallback, /* collapsed = false */ }) => {
  /* const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const toggleTPicker = () => setIsCollapsed(p => !p); */
  /* const [picking, setPicking] = useState((templIndex > -1) ? true : false); */
  const pick = (index) => pickCallback(index);
  return (
    <>
      <TPBox /* collapsed={isCollapsed} */>
        <ScrollContainer className="scrollerz">
          <TPItemScroll>
            <TPItem onClick={() => pick(0)}><img alt="Single Diamond" src={iconSingleCless} /><p>NATURAL DIAMOND</p></TPItem>
            <TPItem onClick={() => pick(1)}><img alt="Single Color" src={iconSingleColor} /><p>COLOR GEMSTONE</p></TPItem>
            <TPItem onClick={() => pick(0)}><img alt="Batch Diamond" src={iconBatchCless} /><p>NATURAL DIAMOND BATCH</p></TPItem>
            <TPItem onClick={() => pick(1)}><img alt="Batch Color" src={iconBatchColor} /><p>COLOR GEMSTONE BATCH</p></TPItem>
            <TPItem onClick={() => pick(0)}><img alt="Single Diamond" src={iconSingleCless} /><p>NATURAL DIAMOND</p></TPItem>
            <TPItem onClick={() => pick(1)}><img alt="Single Color" src={iconSingleColor} /><p>COLOR GEMSTONE</p></TPItem>
            <TPItem onClick={() => pick(0)}><img alt="Batch Diamond" src={iconBatchCless} /><p>NATURAL DIAMOND BATCH</p></TPItem>
            <TPItem onClick={() => pick(1)}><img alt="Batch Color" src={iconBatchColor} /><p>COLOR GEMSTONE BATCH</p></TPItem>
            <TPItem onClick={() => pick(0)}><img alt="Single Diamond" src={iconSingleCless} /><p>NATURAL DIAMOND</p></TPItem>
            <TPItem onClick={() => pick(1)}><img alt="Single Color" src={iconSingleColor} /><p>COLOR GEMSTONE</p></TPItem>
            <TPItem onClick={() => pick(0)}><img alt="Batch Diamond" src={iconBatchCless} /><p>NATURAL DIAMOND BATCH</p></TPItem>
            <TPItem onClick={() => pick(1)}><img alt="Batch Color" src={iconBatchColor} /><p>COLOR GEMSTONE BATCH</p></TPItem>
          </TPItemScroll>
        </ScrollContainer>
      </TPBox>
      <TPFmts>
        <img alt="Full English" src={iconRepFullEng} />
        <img alt="Full Italiano" src={iconRepFullIta} />
        <img alt="Short English" src={iconRepShortEng} />
        <img alt="Short Italiano" src={iconRepShortIta} />
        <img alt="Full WDG" src={iconRepFullWDG} />
      </TPFmts>
    </>
  );
}

const TPBox = styled.div`
  position: relative;
  width: 100%;
  height: calc(1.0 * (200px + 6vw));
  padding: 0 0 calc(1.0 * (14px + 1.0vw)) 0;
  /* background-color: yellow; */
  transition: all 0.4s;
  max-height: ${props => props.collapsed ? "0px" : "1000px"};
  overflow: hidden;
  .scrollerz {
    position: relative;
    /* left: 0;
    top: 10px; */
    height: calc(0.8 * (200px + 6vw));
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: #00000000;
    margin: calc(1.0 * (14px + 1.0vw)) 0;
  }
`;

const TPItemScroll = styled.div`
  position: absolute; top: 0;left: 0;height: 100%;
  width: auto;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const TPFmts = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: calc(1.0 * (14px + 1.0vw)) 0;
  /* background-color: #d0d0d0; */
  background-image: linear-gradient(to right, #404040, #303030, #101010);
  img {
    width: calc(0.5 * (200px + 6vw));
    height: auto;
    margin: 0 calc(1.0 * (14px + 1.0vw));
    cursor: pointer;
    transition: all 0.25s;
    transform: scale(1.0);
    opacity: 0.8;
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        transform: scale(1.1);
        opacity: 1.0;
      }
    }
  }
`;

const TPItem = styled.div`
  position: relative;
  width: calc(0.6 * (200px + 6vw));
  height: calc(0.6 * (200px + 6vw));
  border-radius: calc(0.15 * (200px + 6vw));
  margin: 0 calc(1.0 * (14px + 1.0vw));
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* background-color: #404040c0; */
  background-image: linear-gradient(to bottom, #484848, #282828);
  box-shadow: 3px 3px 6px #000000b0;
  img {
    width: 40%;
    height: auto;
    margin-bottom: calc(0.1 * (14px + 1.0vw));
  }
  p {
    margin-top: calc(0.1 * (14px + 1.0vw));
    width: 80%;
    font-size: calc(12px + 0.1vw);
    text-transform: uppercase;
    font-weight: 500;
    color: #fffffff0;
  }
  cursor: pointer;
  transition: all 0.25s;
  transform: scale(1.0);
  opacity: 0.8;
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.1);
      opacity: 1.0;
    }
  }
`;

export default TemplatePicker;
