import Item2TextGradeSmart from "comps/Item2TextGradeSmart";
import Item2Title from "comps/Item2Title";
import { frame2ScaledPageHPadding } from "data/Config";
import styled from "styled-components";

export const GradeItemBlock = ({ title = "GRADE ITEM BLOCK", imgSrc, y }) => {
  return (
    <GIBBox y={y}>
      <Item2Title title={title} />
      <Item2TextGradeSmart gradeName="Cut" />
      <Item2TextGradeSmart gradeName="Clarity" />
    </GIBBox>
  );
}

const GIBBox = styled.div`
  position: absolute;
  top: ${props => props.y + "%"};
  left: 0;
  width: 100%;
  padding: ${"0 " + frame2ScaledPageHPadding};
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export default GradeItemBlock;
