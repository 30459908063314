import templateDb from "data/TemplateDatabase";

const reportDb = [
  {
    id: 0,
    title: "report0000",
    template: templateDb[0],
    extra: {
      notes: "",
      userEmail: "",
      photos: []
    },
    status: "ready",
    printed: true
  },
  {
    id: 1,
    title: "report0001",
    template: templateDb[1],
    extra: {
      notes: "",
      userEmail: "",
      photos: []
    },
    status: "ready",
    printed: false
  },
  {
    id: 2,
    title: "report0002",
    template: templateDb[0],
    extra: {
      notes: "",
      userEmail: "",
      photos: []
    },
    status: "open",
    printed: false
  },
  {
    id: 3,
    title: "report0003",
    template: templateDb[1],
    extra: {
      notes: "",
      userEmail: "",
      photos: []
    },
    status: "open",
    printed: false
  }
];

export default reportDb;
