import imgHeaderF0L0 from 'img/more/imgHeader0-f0-l0-1024-400.png';
import imgHeaderF1L0 from 'img/more/imgHeader0-f1-l0-1024-400.png';
import imgScalesF0L0 from 'img/more/imgScales-f0-l0-336-350.png';
import imgScalesF1L0 from 'img/more/imgScales-f1-l0-863-348.png';
import styled from "styled-components";

const imagesMap = new Map([
  ["header-f0-l0", imgHeaderF0L0],
  ["header-f1-l0", imgHeaderF1L0],
  ["scales-f0-l0", imgScalesF0L0],
  ["scales-f1-l0", imgScalesF1L0]
]);

const ImageItem = ({ imgSrc = "scales-f1-l0" }) => {
  return (
    <IIBox>
      <img src={imagesMap.get(imgSrc)} alt="" />
    </IIBox>
  );
}

const IIBox = styled.div`
  position: relative;
  width: 100%;
  img {
    width: 100%;
    height: auto;
    mix-blend-mode: darken;
  }
`;

export default ImageItem;
