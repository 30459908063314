export const MenuItems = {
  items: ["home", "about"]
};

export const menuItems00 = [
  {
    title: 'REPORT', icon: "fas fa-home", parent: true, children: [
      { title: 'Nuovo', parent: false, link: '/' },
      { title: 'Aperti', parent: false, link: '/' },
      { title: 'Database', parent: false, link: '/' }]
  },
  { title: 'TEMPLATE', icon: "fas fa-phone", parent: false, link: '/' },
  { title: 'UTENTI', icon: "fas fa-phone", parent: false, link: '/' },
  { title: 'IMPOSTAZIONI', icon: "fas fa-phone", parent: false, link: '/' }/*,
  { title: 'EXECUTING', icon: "fas fa-code", parent: false, link: '/#baking' },
  { title: 'THE TEAM', icon: "fas fa-handshake", parent: false, link: '/#cooks' },
  { title: 'CONTACTS', icon: "", parent: false, link: '/#contacts' } */
];
