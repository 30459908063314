import styled, { css } from 'styled-components';
import imgInclusionMap from 'img/more/inclmap0.png';
import { useState } from "react";

const InclusionMapModal = () => {
  const [incl, setIncl] = useState([]);
  const [menu, setMenu] = useState(false);
  const [clickX, setClickX] = useState(-1);
  const [clickY, setClickY] = useState(-1);

  const closeMenu = () => { setMenu(false); };

  const inClick = (e) => {
    let currTargetRect = e.currentTarget.getBoundingClientRect();
    const eOffX = e.clientX - currTargetRect.left;
    const eOffY = e.clientY - currTargetRect.top;
    setClickX(eOffX);
    setClickY(eOffY);
    setMenu(m => !m);
  };

  const addInclusion = (type) =>
    setIncl(i => i.concat([{ type: type, x: clickX, y: clickY }]));

  return (
    <Over0>
      <img onClick={(e) => inClick(e)} alt={"Click to add an inclusion"} src={imgInclusionMap} />
      {
        incl.map(item =>
          <InclusionMarker type={item.type} x={item.x} y={item.y} />
        )
      }
      {menu &&
        <InclusionMenu dismiss={closeMenu} addInclusion={addInclusion} x={clickX} y={clickY} />
      }
    </Over0>
  );
}


const InclusionMenu = ({ dismiss, addInclusion, x, y }) => {
  return (
    <IMBox0 onClick={dismiss}>
      <IMBox x={x} y={y} >
        <p onClick={(e) => { addInclusion(0); /* e.stopPropagation(); */ }}>FEATHER</p>
        <p onClick={() => addInclusion(1)}>SPECK OF DUST</p>
        <p onClick={() => addInclusion(2)}>PINPOINT</p>
        <p onClick={() => addInclusion(3)}>EXTRA-FACET</p>
      </IMBox>
    </IMBox0>
  );
}

const IMBox0 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const IMBox = styled.div`
  position: absolute;
  left: 0/* ${props => props.x + "px"} */;
  top: 0/* ${props => props.y + "px"} */;
  transform: ${props => "translate(calc(100px + " + props.x + "px), calc(100px + " + props.y + "px))"};
  display: ${props => (props.y > -1 && props.x > -1) ? "block" : "none"};
  z-index: 101;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 25px;
  overflow: hidden;
  p {
    position: relative;
    /* width: 200px;
    height: 60px; */
    padding: 12px 24px;
    color: #ffffff;
    font-size: calc(12px + 0.2vw);
    font-weight: 500;
    background-color: #202020;
    background-image: linear-gradient(to bottom, #40404060, #00000060);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s;
    cursor: pointer;
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        color: #000000;
        background-color: #f0f0f0;
      }
    }
  }
`;

const InclusionMarker = styled.div`
  position: absolute;
  left: 0/* ${props => props.x + "px"} */;
  top: 0/* ${props => props.y + "px"} */;
  ${props => props.type === 0 ?
    css`
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: red;
    ` : (props.type === 1 ?
      css`
      width: 20px;
      height: 10px;
      border-radius: 5px;
      background-color: blue;
    ` :
      (props.type === 2 ?
        css`
      width: 10px;
      height: 20px;
      border-radius: 5px;
      background-color: yellow;
    ` :
        css`
      width: 20px;
      height: 20px;
      border-radius: 5px;
      background-color: seagreen;
    `))
  };
  transform: ${props => "translate(calc(100px + " + props.x + "px), calc(100px + " + props.y + "px))"};
  display: ${props => (props.y > -1 && props.x > -1) ? "block" : "none"};
  z-index: 101;
`;

const Over0 = styled.div`
  position: fixed;
  /* top: calc(50% - 186px);
  left: calc(50% - 403px); */
  top: 50%;
  left: 50%;
  padding: 100px;
  box-shadow: 3px 3px 6px #000000b0;
  border-radius: 80px;
  background-color: #ffffffff;
  /* background-image: url(${imgInclusionMap}); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 100;
  transform: translate(-50%, -50%);
  img {
    width: 807px;
    height: 373px;
  }
`;

export default InclusionMapModal;
