const itemTypes = [
  "txt", "scale", "img", "inclusionMap"
];

/* Color Gemstone Short English Report */
export const cgem2Eng = {
  title: "Color Gemstone Short English Report",
  pages: [
    {
      num: 1,
      blocks: [
        {
          title: "",
          yPerc: 2,
          items: [
            { name: "Header", type: "img", img: "header-f1-l0", imgWidthPerc: 90 }
          ]
        },
        {
          title: "GEM REPORT",
          yPerc: 28,
          items: [
            { name: "Date", type: "txt", defValue: "1 January 2021" },
            { name: "Report Number", type: "txt", defValue: "1023029feffefe239r" }
          ]
        },
        {
          title: "CHARACTERISTICS",
          yPerc: 50,
          items: [
            { name: "Species", type: "txt", defValue: "0.0 ct" },
            { name: "Variety", type: "txt", /* scaleValues: ["Excellent", "Very Good", "Good", "Fair", "Poor"] */scaleValues: ["F", "IF", "VVS1", "VVS2", "VS1", "VS2", "SI1", "SI2", "I1", "I2", "I3"], defValue: "0" },
            { name: "Carat", type: "txt", scaleValues: ["Colorless", "Near Colorless", "Faint", "Very Light", "Light"], defValue: "0" },
            { name: "Measurements", type: "txt", scaleValues: ["Excellent", "Very Good", "Good", "Fair", "Poor"], defValue: "0" },
            { name: "Shape and Cut", type: "txt", scaleValues: ["Excellent", "Very Good", "Good", "Fair", "Poor"], defValue: "0" },
            { name: "Color", type: "txt", scaleValues: ["Excellent", "Very Good", "Good", "Fair", "Poor"], defValue: "0" }
          ]
        },
        /* {
          title: "COMMENTS",
          yPerc: 70,
          items: [
            { name: "Comments", type: "notes", defValue: "No indication of clarity modification" }
          ]
        } */
      ]
    },
    {
      num: 2, blocks: [
        /* {
          title: "PHOTO",
          p: 2, yPerc: 15,
          items: [
            { name: "Photo", type: "photo", img: "scales-f1-l0", imgWidthPerc: 80 }
          ]
        }, */
        /* {
          title: "PHOTO",
          p: 2, yPerc: 15,
          items: [
            { name: "Photo", type: "img", img: "header-f1-l0", imgWidthPerc: 90 }
          ]
        },
        {
          title: "",
          p: 1, yPerc: 80,
          items: [
            { name: "Footer", type: "img", img: "header-f1-l0", imgWidthPerc: 100 }
          ]
        } */
      ]
    }
  ]
};
