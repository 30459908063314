import { ScalePickerControlled } from "comps/ScalePicker";
import { useState } from "react";
import { createPortal } from "react-dom";
import { TxtInput } from "ReportRenderForm";
import styled from 'styled-components';

const F2EditorComp = ({ gradeName, pickCallback, items, selectedItemIndex, clickOut }) => {
  return (
    <F2Editor clickOut={clickOut}>
      <F2EditorDialog onClick={(e) => e.stopPropagation()}>
        <h3>{gradeName}</h3>
        <ScalePickerControlled sel={selectedItemIndex} pickCallback={pickCallback} items={items} />
      </F2EditorDialog>
    </F2Editor>
  );
}
const Frame2ToolTip = styled.div`
  position: absolute;
  left: 60%;
  top: 90%;
  width: 30vw;
  padding: calc(1.0 * (14px + 1.0vw));
  background-color: #fffffff0;
  z-index: 999;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: space-evenly;
  font-size: calc(12px + 0.3vw);
  font-weight: 400;
  color: #222222;
  text-align: center;
  box-shadow: 3px 3px 6px #000000b0;
  border-radius: 25px;
  p {margin-bottom: calc(1.0 * (14px + 1.0vw));
  }
  a {
    align-self: flex-end;
    margin-right: calc(1.0 * (14px + 1.0vw));
    text-transform: uppercase;font-weight: 600;
    color: #30a2ff; cursor: pointer;
  }
`;

const tooltipDefault = {
  text: <p><strong>Symmetry</strong> is graded on a scale from Excellent to Poor based on the presence and visibility of symmetry deviations at 10x magnification.</p>,
  link: "https://www.google.com/search?q=gia+diamond+symmetry"
}

export const F2EditorCompTxt = ({ gradeName, onChange, items, selectedValue, clickOut }) => {
  const [tooltip, setTooltip] = useState(false);
  const toggleTooltip = () => setTooltip(p => !p);
  return (
    <F2Editor clickOut={clickOut}>
      {
        tooltip &&
        <Frame2ToolTip onClick={() => setTooltip(false)}>
          {tooltipDefault.text}
          <a href={tooltipDefault.link} target="_blank" rel="noopener noreferrer">Read more</a>
        </Frame2ToolTip>
      }
      <F2EditorDialog onClick={(e) => e.stopPropagation()}>
        <h3>{gradeName}<i onClick={toggleTooltip} className="fmicnfo fas fa-info-circle" /></h3>
        <TxtInput
          type="text"
          value={selectedValue}
          onChange={onChange}
        />
        {/* <ScalePickerControlled sel={selectedItemIndex} pickCallback={pickCallback} items={items} /> */}
      </F2EditorDialog>
    </F2Editor>
  );
}

const F2Editor = ({ children, clickOut }) => {
  /* const [grade, setGrade] = useState(0);
  const [showScale, setShowScale] = useState(false);
  const pickCallback = (e, index) => { setGrade(index % scale.length); e.stopPropagation(); } */

  return (
    <F2Editor0 onClick={clickOut}>
      <F2EditorBox>
        {children}
      </F2EditorBox>
    </F2Editor0>
    /* , document.getElementById("modal-root")
  ) */
  );
}

const F2Editor0 = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000030;
  z-index: 999;
`;

const F2EditorBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  /* padding: calc(2.0 * (14px + 1.0vw)); */
  transform: translate(-50%, -50%);
  z-index: 11;
`;

const F2EditorDialog = styled.div`
  position: relative;
  padding: 100px  40px 40px 40px;
  background-color: #ffffffff;
  box-shadow: 3px 3px 6px #000000b0;
  border-radius: 50px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  i {
    margin: 0 12px;
    }
  h3 {
    position: absolute;
    top: 40px;
    left:50%;
    transform: translate(-50%, -50%);
    width: auto ;
    text-align: start;
    color: #0000CC;
    /* font-size: 16px; */
    /* font-size: ${(48 * 100 / 1441.0) + "vh"}; */
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    line-height: 1;
    /* margin-bottom: 12px; */

    margin-bottom: ${(28 * 100 / 1441.0) + "vh"};
    &::after {
      content: '';
      position: absolute;
      /* bottom: -6px; */
      bottom: ${(-16 * 100 / 1441.0) + "vh"};
      left: 0;
      width: 100%;
      height: 1px;
      background: #0000CC;
    }
  }
`;

export default F2EditorComp;
