import { gradeTitleColorStr } from "data/Config";
import styled from 'styled-components';

const Item2Title = ({ title = "GRADING RESULTS" }) => {
  return (
    <IT2Box>
      <IT2Title>{title}</IT2Title>
    </IT2Box>
  );
}

const IT2Title = styled.p`
  position: relative;
  font-size: calc((18.0 / 922.0) * 92vh);
  font-weight: 600;
`;

const IT2Box = styled.div`
  position: relative;
  width: 100%;
  color: ${gradeTitleColorStr};
  /* font-size: calc(1.0 * (14px + 1.0vw)); */
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-align: start;
  line-height: 1;
  margin-bottom: calc((24.0 / 922.0) * 92vh);
  &::after {
    content: '';
    position: absolute;
    bottom: calc((-18.0 / 922.0) * 92vh);
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${gradeTitleColorStr};
  }
`;


export const Item2FormTitle = ({ click, title = "GRADING RESULTS" }) => {
  return (
    <IT2FBox onClick={click}>
      <IT2TFitle>{title}</IT2TFitle>
    </IT2FBox>
  );
}

const IT2TFitle = styled.p`
  position: relative;
  font-size: calc(14px + 0.4vw);
  font-weight: 600;
`;

const IT2FBox = styled.div`
  position: relative;
  width: 100%;
  color: ${gradeTitleColorStr};
  cursor: pointer;
  /* font-size: calc(1.0 * (14px + 1.0vw)); */
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-align: end;
  line-height: 1;
  margin-bottom: calc(1.2 * (14px + 1.0vw));
  &::after {
    content: '';
    position: absolute;
    bottom: calc(-0.4 * (14px + 1.0vw));
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${gradeTitleColorStr};
  }
`;

export default Item2Title;
