import { ReportContext } from "App";
import Frame2 from "comps/Frame2";
import Frame3 from "comps/Frame3";
import { useContext } from "react";
import ReportRenderPreview from "ReportRenderPreview";
import styled from 'styled-components';

const templItems = [
  {
    type: "head2ENG",
    xPerc: 10,
    yPerc: 10,
    wPerc: 80,
    hPerc: 20
  }
];

const Preview = () => {
  const reportContext = useContext(ReportContext);
  return (
    <PreviewBox>
      <ReportRenderPreview />
      {/* reportContext.templFmtPps === 2 ?
        <Frame2 templItems={templItems} />
        :
        <Frame3 templItems={templItems} /> */
      }
    </PreviewBox>
  );
}

const PreviewBox = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #000000e0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Preview;
