import InclusionMapItem from "comps/InclusionMapItem";
import { frame2r, frame2ScaledPageHPadding } from "data/Config";
import StaticImgItem from "comps/StaticImgItem";
import GradeItemBlock from "comps/GradeItemBlock";
import styled from 'styled-components';
import imgHeader from 'img/more/imgHeader0-f1-l0-1024-400.png';
import { useState } from "react";

const scale = "(92vh / 1027px)";

const Frame2ToolTip = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 40vw;
  padding: calc(1.0 * (14px + 1.0vw));
  background-color: #fffffff0;
  z-index: 999;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: space-evenly;
  font-size: calc(12px + 0.3vw);
  font-weight: 400;
  color: #222222;
  text-align: center;
  box-shadow: 3px 3px 6px #000000b0;
  border-radius: 25px;
  p {margin-bottom: calc(1.0 * (14px + 1.0vw));
  }
  a {
    align-self: flex-end;
    margin-right: calc(1.0 * (14px + 1.0vw));
    text-transform: uppercase;font-weight: 600;
    color: #30a2ff; cursor: pointer;
  }
`;

const tooltipDefault = {
  text: <p><strong>Symmetry</strong> is graded on a scale from Excellent to Poor based on the presence and visibility of symmetry deviations at 10x magnification.</p>,
  link: "https://www.google.com/search?q=gia+diamond+symmetry"
}

const Frame2 = () => {
  const [tooltip, setTooltip] = useState(false);
  const toggleTooltip = () => setTooltip(p => !p);
  return (
    <Frame2Box>

      {
        tooltip && <Frame2ToolTip onClick={() => setTooltip(false)}>
          {tooltipDefault.text}
          <a href={tooltipDefault.link} target="_blank" rel="noopener noreferrer">Read more</a>
        </Frame2ToolTip>
      }

      <Frame2Page>
        {/* <StaticImgItem title="image item" y={0} /> */}
        <StaticImgItem imgSrc={imgHeader} y={75.0 / 14.41} />
        <GradeItemBlock title="NATURAL DIAMOND GRADING REPORT" y={675.0 / 14.41} />
        <GradeItemBlock title="GRADING RESULTS" y={1225.0 / 14.41} />
        {/* <Frame2Item y={375.0 / 14.41}>
          <Item2Title title="NATURAL DIAMOND GRADING REPORT" />
        </Frame2Item>
        <Frame2Item y={651.0 / 14.41}>
          <Item2Title title="GRADING RESULTS" />
        </Frame2Item> */}
        {/* <Frame2Item>
          <ImageItem imgSrc="header-f1-l0" />
        </Frame2Item>
        <Frame2Item>
          <Item2Title />
        </Frame2Item>
        <Frame2Item>
          <ScaleGrade />
        </Frame2Item>
        <Frame2Item>
          <InclusionMap />
        </Frame2Item> */}
      </Frame2Page>
      <Frame2Page>
        <InclusionMapItem title="CLARITY CHARACTERISTICS" y={555.0 / 14.41} />
        {/* <StaticImgItem imgSrc={imgHeader} title="image item" y={(1441.0 - 344.0) / 14.41} /> */}
        {/* <Frame2Item>
          <ImageItem imgSrc="scales-f1-l0" />
        </Frame2Item> */}
      </Frame2Page>
    </Frame2Box>
  );
}


const Frame2Box = styled.div`
  position: relative;
  height: 92vh;
  width: auto;
  /* width: ${"calc(92vh / " + frame2r + ")"}; */
  background-color: #ffffff;
  /* background-image: linear-gradient(to right, #00000000 0%, #00000000 25%, #00000070 50%, #00000000 50%, #00000000 75%, #00000070 100%); */
  display: flex;
  flex-flow: row wrap;
  justify-content: /* space-evenly; */center;
  align-items: center;
`;

const Frame2Page = styled.div`
  position: relative;
  /* height: 100%; */
  height: 92vh;
  /* width: 50%; */
  background-image: linear-gradient(to right, #00000000 0%, #00000000 50%, #00000070 100%);
  width: ${"calc(46vh / " + frame2r + ")"};
  /* padding: ${"0 " + frame2ScaledPageHPadding}; */
`;

export default Frame2;
