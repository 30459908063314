import { ScalePickerControlled } from "comps/ScalePicker";
import { useState } from "react";
import { createPortal } from "react-dom";
import styled from 'styled-components';

const ScaleGrade = ({ name = "Cut", value, scale = ["Excellent", "Very Good", "Good", "Fair", "Poor"] }) => {
  const [grade, setGrade] = useState(scale.indexOf(value) > -1 ? scale.indexOf(value) : 0);
  const [showScale, setShowScale] = useState(false);
  const pickCallback = (e, index) => { setGrade(index % scale.length); e.stopPropagation(); setShowScale(false); }

  return (
    <SGBox>
      <div onClick={() => setShowScale(true)} className="f2ir"><p>{name}</p><p>{scale[grade]}</p></div>
      {showScale &&
        <F2Editor clickOut={() => setShowScale(false)}>
          <F2EditorDialog>
            <h3>{name}</h3>
            <ScalePickerControlled pickCallback={pickCallback} items={scale} />
          </F2EditorDialog>
        </F2Editor>
      }
    </SGBox>
  );
}

const SGBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  .f2ir {
    width: 96%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
`;

const F2EditorDialog = styled.div`
  position: relative;
  padding: 100px  40px 40px 40px;
  background-color: #ffffffff;
  box-shadow: 3px 3px 6px #000000b0;
  border-radius: 50px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  h3 {
    position: absolute;top: 40px;left:30%;
    transform: translate(-50%, -50%);
    width: 30%;
    text-align: start;
    color: #0000CC;
    /* font-size: 16px; */
    font-size: ${(48 * 100 / 1441.0) + "vh"};
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    line-height: 1;
    /* margin-bottom: 12px; */
    margin-bottom: ${(28 * 100 / 1441.0) + "vh"};
    &::after {
      content: '';
      position: absolute;
      /* bottom: -6px; */
      bottom: ${(-16 * 100 / 1441.0) + "vh"};
      left: 0;
      width: 100%;
      height: 1px;
      background: #0000CC;
    }
  }
`;

const F2Editor = ({ children, clickOut }) => {
  /* const [grade, setGrade] = useState(0);
  const [showScale, setShowScale] = useState(false);
  const pickCallback = (e, index) => { setGrade(index % scale.length); e.stopPropagation(); } */

  return (
    createPortal(
      <F2Editor0 onClick={clickOut}>
        <F2EditorBox>
          {children}
        </F2EditorBox>
      </F2Editor0>
      , document.getElementById("modal-root")
    )
  );
}

const F2Editor0 = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000030;
  z-index: 10;
`;

const F2EditorBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  /* padding: calc(2.0 * (14px + 1.0vw)); */
  transform: translate(-50%, -50%);
  z-index: 11;
`;


export default ScaleGrade;
