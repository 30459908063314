import reportDb from "data/ReportDatabase";
import templateDb from "data/TemplateDatabase";
import { useState } from "react";
import styled from 'styled-components';

const RRepo = () => {

  const [tDb, setTDb] = useState(templateDb);
  const [rDb, setRDb] = useState(reportDb);

  return (
    <RRepoBox>
      {
        (rDb && rDb.length > 0) ?
          rDb.map((item) =>
            <RRepoLine status={item.status} printed={item.printed}>
              <p className="rrepotitleitem">{item.title}</p>
              <RRButton><p>VIEW</p><i className="fas fa-eye" /></RRButton>
              <RRButton><p>EDIT</p><i className="fas fa-wrench" /></RRButton>
              <RRButton><p>SHARE</p><i className="fas fa-share-alt" /></RRButton>
              <RRButton><p>FILE</p><i className="fas fa-download" /></RRButton>
            </RRepoLine>
          )
          :
          <p>Empty report database</p>
      }
    </RRepoBox>
  );
}

const RRepoLine = styled.div`
  position: relative;
  padding: calc(1.0 * (14px + 1.0vw)) calc(2.0 * (14px + 1.0vw));
  width: 100%;
  background-image: linear-gradient(to bottom, #00000000, #00000000, #00000010);
  /* background-size: 100px 100px;
  background-position: center;
  background-repeat: no-repeat; */
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  .rrepotitleitem {
    flex-grow: 10;
    position: relative;
    font-size: calc(14px + 0.4vw);
    text-align: start;
    padding-left: 30px;
    margin: 0 calc(0.8 * (14px + 1.0vw));
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: calc(1.0 * (14px + 1.0vw));
      height: calc(1.0 * (14px + 1.0vw));
      border-radius: calc(0.5 * (14px + 1.0vw));
      /* background-color: ${props => props.status === "open" ? "#ffff90c0" : (props.printed === true ? "#9090ffc0" : "#ff9090c0")}; */
      background-color: ${props => props.status === "open" ? "#c00000a0" : (props.printed === true ? "#0000c0a0" : "#00c000a0")};
      background-image: linear-gradient(135deg, #00000000, #00000000, #00000090);
      transform: translate(-100%, -50%);
    }
  }
`;

const RRButton = styled.div`
  position: relative;
  /* display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center; */
  cursor: pointer;
  margin: 0 calc(0.8 * (14px + 1.0vw));
  transition: all 0.2s;
        color: #30a2ffc0;
  p {
    display: inline-block;
    font-size: calc(16px + 0.2vw);
    font-weight: 600;
    text-transform: uppercase;
  }
  i {
    display: inline-block;
    position: relative;
    font-size: calc(24px + 0.2vw);
    margin: 0 calc(0.3 * (14px + 1.0vw));
    transform: scale(1.0);
  }
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        transform: scale(1.1);
        color: #30a2fff0;
      }
    }
`;

const RRepoBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export default RRepo;
