import ImageItem from "comps/ImageItem";
import { InclusionMap } from "comps/InclusionMapItem";
import Item2TextGradeSmart from "comps/Item2TextGradeSmart";
import Item2Title, { Item2FormTitle } from "comps/Item2Title";
import { ScalePickerControlled } from "comps/ScalePicker";
import { frame2r, frame2ScaledPageHPadding } from "data/Config";
import { ndia2Eng } from "data/NaturalDiamondShortEng";
import reportDb from "data/ReportDatabase";
import { debounce } from "lodash";
import { createContext, useEffect, useState } from "react";
import styled from "styled-components";
import ImageUploader from "react-images-upload";
import { Toggle, ToggleSmart } from "comps/Toggle";

import iconSingleCless from 'img/icons/single-cless-100-0.png';

const Frame2ToolTip = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 40vw;
  padding: calc(1.0 * (14px + 1.0vw));
  background-color: #fffffff0;
  z-index: 999;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: space-evenly;
  font-size: calc(12px + 0.3vw);
  font-weight: 400;
  color: #222222;
  text-align: center;
  box-shadow: 3px 3px 6px #000000b0;
  border-radius: 25px;
  p {margin-bottom: calc(1.0 * (14px + 1.0vw));
  }
  a {
    align-self: flex-end;
    margin-right: calc(1.0 * (14px + 1.0vw));
    text-transform: uppercase;font-weight: 600;
    color: #30a2ff; cursor: pointer;
  }
`;

const tooltipDefault = {
  text: <p><strong>Symmetry</strong> is graded on a scale from Excellent to Poor based on the presence and visibility of symmetry deviations at 10x magnification.</p>,
  link: "https://www.google.com/search?q=gia+diamond+symmetry"
}

const TPItem = styled.div`
  /* position: relative; */
  position: absolute;
  top: 0;
  right: 0;
  width: calc(0.6 * (200px + 6vw));
  height: calc(0.6 * (200px + 6vw));
  border-radius: calc(0.15 * (200px + 6vw));
  margin: 0 calc(1.0 * (14px + 1.0vw));
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* background-color: #404040c0; */
  background-image: linear-gradient(to bottom, #484848, #282828);
  box-shadow: 3px 3px 6px #000000b0;
  img {
    width: 40%;
    height: auto;
    margin-bottom: calc(0.1 * (14px + 1.0vw));
  }
  p {
    margin-top: calc(0.1 * (14px + 1.0vw));
    width: 80%;
    font-size: calc(12px + 0.1vw);
    text-transform: uppercase;
    font-weight: 500;
    color: #fffffff0;
  }
  /* cursor: pointer; */
  transition: all 0.25s;
  transform: scale(0.8) translate(50%, -25%);
  opacity: 0.5;
  /* @media(hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.1);
      opacity: 1.0;
    }
  } */
`;

const ReportRenderForm = ({ report,/* report = ndia2Eng, */ reportIndex = 0 }) => {
  /* const [pages, setPages] = useState([...(reportDb[reportIndex].template.template.pages)]); */
  const [pages, setPages] = useState([...(report.template.template.pages)]);

  const [pictures, setPictures] = useState([]);
  const onDrop = picture => {
    setPictures([...pictures, picture]);
    console.log("pic: ");
    console.log(picture);
  };

  useEffect(() => { setPages(null); }, [reportIndex]);
  useEffect(
    () => {
      if (pages === null) {
        setPages([...(report.template.template.pages)])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pages]
  );


  return (
    <RRF2PBox>
      <TPItem onClick={() => { }}><img alt="Single Diamond" src={iconSingleCless} /><p>NATURAL DIAMOND</p></TPItem>
      <h3 className="rrftitle">{report.title}</h3>
      {pages && pages.map((item) =>
        <PageRenderForm page={item} />
      )}
      <RPFormExtra>
        <h3 className="rrftitle">Extra data</h3>
        <IRF3Box>
          <p className="formitemlabel">OWNER</p>
          <ItemTxtForm gradeName={"OWNER"} gradeValue={"RobertReportOwner@gmail.com"} />
        </IRF3Box>
        <IRF3Box>
          <p className="formitemlabel">ADDITIONAL NOTES</p>
          <ItemTxtFormXL gradeName={"ADDITIONAL NOTES"} gradeValue={""} />
        </IRF3Box>
        <IRF3Box>
          <p className="formitemlabel">PHOTOS</p>
          <ImageUploader
            withIcon={true} withPreview={true}
            buttonText="Choose images"
            onChange={onDrop}
            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
            maxFileSize={5242880}
          /></IRF3Box>
        {/* <RPExtraPhotos>
          {(pictures && pictures.length > 0) &&
            pictures.map(
              (picture, index) =>
                <img src={picture} />
            )
          }
        </RPExtraPhotos> */}
        <br /><ToggleSmart selected={report.status === "open"} t1="Open" t2="Ready" t3="Printed" /><br />
      </RPFormExtra >
    </RRF2PBox >
  );
}

const RPExtraPhotos = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  height: 800px;
  img {position: relative;width: 200px; height: auto;}
`;

const RPFormExtra = styled.div`
  position: relative;
  width: 100%;
`;

const RRF2PBox = styled.div`
  position: relative;
  height: auto;
  /* width: ${"calc(92vh / " + frame2r + ")"}; */
  width: auto;
  max-width: 1000px;
  margin: auto;
  padding: 0 0 calc(3.0 * (14px + 1.0vw)) 0;
  img {
    width: 100px;
    height: auto;
  }
  /* background-image: linear-gradient(to right, #00000000 0%, #00000000 25%, #00000070 50%, #00000000 50%, #00000000 75%, #00000070 100%); */
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  /* background-color: red; */
  .rrftitle {
    width: 100%;
    font-weight: 600;
    text-transform: uppercase;
    color: #202020;
    line-height: 2;
    margin: calc(1.0 * (14px + 1.0vw));
    background-image: linear-gradient(to right, #00000000, #00000018, #00000000);
  }
`;

const PageRenderForm = ({ page }) => {
  const [blocks, setBlocks] = useState(page.blocks);

  return (
    <RPFR2PBox>
      {blocks.map((item, index) =>
        <BlockRenderForm key={"" + item.title + item.yPerc + index} defCollapsed={(page.num === 1 && (index === 0 || index === 1)) ? false : true} block={item} />
      )}
    </RPFR2PBox>
  );
}

const RPFR2PBox = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  /* height: 92vh;
  margin: 4vh 0; */
  /* width: 50%; */
  /* width: ${"calc(46vh / " + frame2r + ")"}; */
  /* background-color: #ffffff;
  background-image: linear-gradient(104deg, #00000000 0%, #00000000 50%, #00000070 100%); */
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const BlockRenderForm = ({ defCollapsed = false, block }) => {
  const [items, setItems] = useState(block.items);
  const [collapsed, setCollapsed] = useState(defCollapsed);
  const toggleCollapse = () => setCollapsed(i => !i);
  return (
    <BRFBox collapsed={collapsed} y={block.yPerc}><i className={collapsed ? "expicon fas fa-plus" : "expicon fas fa-minus"} />
      {(block.title && block.title.length > 0) && <Item2FormTitle click={toggleCollapse} title={block.title} />}
      {items.map((item) =>
        <ItemRenderForm item={item} />
      )}
    </BRFBox>
  );
}

const BRFBox = styled.div`
.expicon {position: absolute;
top: 0;
left: 4px;
color: #30a2ff90;
font-size: calc(12px + 0.2vw);
}
  position: relative;
  /* position: absolute;
  top: ${props => props.y + "%"};
  left: 0px; */
  width: 100%;
  transition: all 0.4s;
  max-height: ${props => props.collapsed ? "40px" : "2000px"};
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 /* calc(2 * (14px + 1.0vw)) */ 0 calc(1 * (14px + 1.0vw)) 0;
`;

/* <I2TGBox>
  <p>{gradeName}</p>
  <strong>{gradeScale[currGrade]}</strong> */

/* <F2EditorComp gradeName={gradeName} pickCallback={pickCallback} selectedItemIndex={currGrade} items={gradeScale} clickOut={() => setShowScale(false)} /> */
/* </I2TGBox> */
const ItemScaleForm = ({ gradeName = "Cut", gradeValue, gradeScale = ["Excellent", "Very Good", "Good", "Fair", "Poor"] }) => {
  const [currGrade, setCurrGrade] = useState(gradeScale.indexOf(gradeValue) > -1 ? gradeScale.indexOf(gradeValue) : 0);
  const pickCallback = (e, index) => { setCurrGrade(index % gradeScale.length); e.stopPropagation(); /* setShowScale(false); */ }
  return (
    <ScalePickerControlled sel={currGrade} pickCallback={pickCallback} items={gradeScale} />
  );
}

const ItemTxtForm = ({ gradeName = "Cut", gradeValue }) => {
  const [currGrade, setCurrGrade] = useState(gradeValue);
  const onChange = event => setCurrGrade(event.target.value);
  return (
    <TxtInput
      type="text"
      value={currGrade}
      onChange={onChange}
    />
  );
}

const ItemTxtFormXL = ({ gradeName = "Cut", gradeValue }) => {
  const [currGrade, setCurrGrade] = useState(gradeValue);
  const onChange = event => setCurrGrade(event.target.value);
  return (
    <TxtInputXL
      type="text"
      value={currGrade}
      onChange={onChange}
    />
  );
}

const ItemRenderForm = ({ item }) => {

  const [tooltip, setTooltip] = useState(false);
  const toggleTooltip = () => setTooltip(p => !p);

  const itemChangeCallback = debounce((itemID, value) => {
    /* update current report item via context */
  }, 20);

  return (item.type === "img" ? null : (
    <IRF3Box>
      <>
        {
          tooltip &&
          <Frame2ToolTip onClick={() => setTooltip(false)}>
            {tooltipDefault.text}
            <a href={tooltipDefault.link} target="_blank" rel="noopener noreferrer">Read more</a>
          </Frame2ToolTip>
        }
        {item.type === "inclusionMap" ?
          <InclusionMap /> : (
            item.type === "scale" ?
              <>
                <p className="formitemlabel">{item.name}</p>
                <ItemScaleForm gradeName={item.name} gradeValue="Very Good" gradeScale={item.scaleValues} />
                <i onClick={toggleTooltip} className="fmicnfo fas fa-info-circle" />
                <i className="fmicedt fas fa-pen" />
              </>
              :

              /* item.type === "txt" ? */
              <>
                <p className="formitemlabel">{item.name}</p>
                <ItemTxtForm gradeName={item.name} gradeValue={item.defValue} />
                <i onClick={toggleTooltip} className="fmicnfo fas fa-info-circle" />
                <i className="fmicedt fas fa-pen" />
              </>
          )
        }
      </>
    </IRF3Box>)
  );
}

export const TxtInput = styled.input`
  position: relative;
  width: 600px;
  border-radius: 20px;
  box-shadow: 3px 3px 6px #00000040;
  font-size: calc(13px + 0.3vw);
  padding: calc(0.45 * (14px + 1.0vw)) calc(0.85 * (14px + 1.0vw));
  border: none;
  outline: none;
`;


export const TxtInputXL = styled.textarea`
  position: relative;
  width: 600px;
  height: 200px;
  border-radius: 20px;
  box-shadow: 3px 3px 6px #00000040;
  font-size: calc(13px + 0.3vw);
  padding: calc(0.45 * (14px + 1.0vw)) calc(0.85 * (14px + 1.0vw));
  border: none;
  outline: none;
`;

const IRF3Box = styled.div`
  position: relative;
  /* width: 100%; */
  /* max-width: 800px; */
  /* height: 280px; */
  padding: calc(0.6 * (14px + 1.0vw)) calc(0.25 * (14px + 0.4vw));
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .fmicnfo {
    color: #143F9090;
    position: absolute;
    font-size: calc(1.2 * (14px + 0.4vw));
    top: 50%;
    left: 0;
    transform: translate(calc(-1.2 * (14px + 0.4vw)), -50%) scale(1.0);
    cursor: pointer;
    transition: transform 0.25s;
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        transform:  translate(calc(-1.2 * (14px + 0.4vw)), -50%) scale(1.5);
      }
    }
  }
  .fmicedt {
    color: #30a2ffc0;
    position: absolute;
    font-size: calc(1.0 * (14px + 0.4vw));
    top: 50%;
    right: 0;
    transform: translate(calc(1.5 * (14px + 0.4vw)), -50%) scale(1.0) ;
    cursor: pointer;
    transition: all 0.25s;
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        /* font-size: calc(1.3 * (14px + 0.4vw)); */
      color: #30a2ffff;
        transform:  translate(calc(1.5 * (14px + 0.4vw)), -50%) scale(1.5);
      }
    }
  }
  @media only screen and (max-width: 800px) {
    flex-flow: column nowrap;
  }
  .formitemlabel {
    font-size: calc(13px + 0.2vw);
    font-weight: 500;
    text-transform: uppercase;
    color: #00000060;
    /* line-height: 2.4; */
    /* align-self: flex-start; */
    padding: 0 0 0 calc(0.8 * (14px + 1.0vw));
  }
  input {
    width: 600px;
    border-radius: 20px;
    box-shadow: 3px 3px 6px #00000040;
    font-size: calc(13px + 0.3vw);
    padding: calc(0.45 * (14px + 1.0vw)) calc(0.85 * (14px + 1.0vw));
    border: none;
    outline: none;
  }
  .inclMapBox {
    max-width: 600px;
    margin: auto;
  }
`;

export default ReportRenderForm;
