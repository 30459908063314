import ImageItem from "comps/ImageItem";
import { InclusionMap } from "comps/InclusionMapItem";
import Item2ScaleGradeSmart from "comps/Item2ScaleGradeSmart";
import Item2TextGradeSmart from "comps/Item2TextGradeSmart";
import Item2Title from "comps/Item2Title";
import { cgem2Eng } from "data/ColorGemstoneShortEng";
import { frame2r, frame2ScaledPageHPadding } from "data/Config";
import { ndia2Eng } from "data/NaturalDiamondShortEng";
import reportDb from "data/ReportDatabase";
import { debounce } from "lodash";
import { createContext, useEffect, useState } from "react";
import styled from "styled-components";

const ReportRenderPreview = ({ report = ndia2Eng, reportIndex = 0 }) => {
  const [pages, setPages] = useState([...(reportDb[reportIndex].template.template.pages)]);

  useEffect(() => { setPages(null); }, [reportIndex]);
  useEffect(
    () => {
      if (pages === null) {
        setPages([...(reportDb[reportIndex].template.template.pages)])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pages]
  );

  return (
    <RR2PBox>
      {pages && pages.map((item) =>
        <PageRenderPreview page={item} />
      )}
      {/* <h3>{report.title}</h3> */}
    </RR2PBox>
  );
}

const RR2PBox = styled.div`
  position: relative;
  height: auto;
  /* width: ${"calc(92vh / " + frame2r + ")"}; */
  width: auto;

  /* background-image: linear-gradient(to right, #00000000 0%, #00000000 25%, #00000070 50%, #00000000 50%, #00000000 75%, #00000070 100%); */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: calc(14px + 0.3vw);
    width: auto;
    padding-top: 24px;
    margin: auto;
    align-self: center;
  }
  /* h3 {
    position : absolute;
    top: 50%;
    left: 50%;
    color: red;
    z-index: 999;
  } */
`;

const PageRenderPreview = ({ page }) => {
  const [blocks, setBlocks] = useState(page.blocks);

  return (
    <RPR2PBox>
      {blocks.map((item) =>
        <BlockRenderPreview block={item} />
      )}
    </RPR2PBox>
  );
}

const RPR2PBox = styled.div`
  position: relative;
  /* height: 100%; */
  height: 92vh;
  margin: 4vh 0;
  /* width: 50%; */
  width: ${"calc(46vh / " + frame2r + ")"};
  background-color: #ffffff;
  background-image: linear-gradient(104deg, #00000000 0%, #00000000 50%, #00000070 100%);
`;

const BlockRenderPreview = ({ block }) => {
  const [items, setItems] = useState(block.items);

  return (
    <BRPBox y={block.yPerc}>
      {(block.title && block.title.length > 0) && <Item2Title title={block.title} />}
      {items.map((item) =>
        <ItemRenderPreview item={item} />
      )}
    </BRPBox>
  );
}

const BRPBox = styled.div`
  position: absolute;
  top: ${props => props.y + "%"};
  left: 0px;
  width: 100%;
  padding: 0 ${frame2ScaledPageHPadding};
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const ItemRenderPreview = ({ item }) => {
  const [complete, setComplete] = useState(false);
  const completeItem = () => setComplete(true);
  return (
    <IRPBox type={item.type} complete={complete}>
      {
        item.type === "scale" ?
          <Item2ScaleGradeSmart firstChangeCallback={completeItem} gradeScale={item.scaleValues} gradeName={item.name} gradeValue={item.scaleValues[0]} />
          :
          (
            item.type === "txt" ?
              <Item2TextGradeSmart firstChangeCallback={completeItem} gradeName={item.name} gradeValue={item.defValue} />
              :
              item.type === "mltxt" ?
                <Item2TextGradeSmart firstChangeCallback={completeItem} gradeName={item.name} gradeValue={item.defValue} />
                :
                (
                  item.type === "img" ?
                    <ImageItem imgSrc={item.img} />
                    :
                    <InclusionMap />
                ))
      }
    </IRPBox>
  );
}

const IRPBox = styled.div`
  position: relative;
  width: 100%;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${props => (props.type === "scale" || props.type === "txt") ? (props.complete === false ? "linear-gradient(to bottom, #ff000030, #aa000030)" : "linear-gradient(to bottom, #00ff0030, #00aa0030)") : "none"};
    pointer-events: none;
  }
  /* height: 50px;
  background-color: red;
  margin: 10px 0; */
  /* display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center; */
`;

export default ReportRenderPreview;
