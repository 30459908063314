import FAB from "comps/FAB";
import InclusionMapModal from "comps/InclusionMapModal";
import Preview from "comps/Preview";
import ProportionsMapModal from "comps/ProportionsMapModal";
import ScaleGrade from "comps/ScaleGrade";
import { ScalePickerControlled } from "comps/ScalePicker";
import TemplatePicker from "comps/TemplatePicker";
import reportDb from "data/ReportDatabase";
import templateDb from "data/TemplateDatabase";
import { createContext, useEffect, useState } from "react";
import { useKey } from "react-use";
import ReportRenderForm from "ReportRenderForm";
import ReportRenderPreview from "ReportRenderPreview";
import RePro from "RePro";
import styled from "styled-components";

import logoPng1024x400 from 'img/more/imgHeader0-f0-l0-1024-400.png';
import RRepo from "RRepo";
import Menu from "comps/Menu";

/*
#143F90
*/

export const ReportContext = createContext(
  {
    completion: false,
    templFmtPps: 2
  }
);

const AppNav2 = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
`;

const AppNav2Item = styled.div`
  position: relative;
  width: ${props => props.sel ? "auto" : "auto"};
  flex-grow: ${props => props.sel ? "1" : "0"};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #143F90;
  padding: calc(1.0 * (14px + 1.0vw));
  background-image: linear-gradient(135deg, #ffffff00, #ffffff30);
  p {
    color: #fffffff0;
    font-size: calc(1.1 * (13px + 0.3vw));
    font-weight: 600;
    display: ${props => props.sel ? "block" : "none"};
  }
  i {
    color: #ffffff60;
    font-size: calc(2.0 * (13px + 0.3vw));
    font-weight: 600;
    margin-right: ${props => props.sel ? "calc(0.4 * (14px + 1.0vw))" : "0"};
  }
`;

const App = () => {
  const [tDb, setTDb] = useState(templateDb);
  const [rDb, setRDb] = useState(reportDb);

  const [openReports, setOpenReports] = useState(rDb.filter((item) => (item.status === "open" /* || item.status === "ready" */)));
  const [openTabs, setOpenTabs] = useState([]/*
    openReports.map((item, index) => ({
      reportIndex: index
    })
    ) */
  );
  useEffect(
    () => setOpenTabs(
      openReports.map((item, index) => ({
        openReportIndex: index
      })
      )
    )
    , [openReports]);

  const [openTab, setOpenTab] = useState(-1);
  const [tPicker, setTPicker] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);
  const menuShow = () => setMenuOpen(true);
  const menuDismissed = () => setMenuOpen(false);

  const [currCompletion, setCurrCompletion] = useState(false);
  const toggleCurrCompletion = () => setCurrCompletion(p => !p);
  useKey("c", toggleCurrCompletion);

  const [currTemplFmtPps, setCurrTemplFmtPps] = useState(2);
  const toggleCurrTemplFmtPps = () => setCurrTemplFmtPps(p => p === 2 ? 3 : 2);
  useKey("f", toggleCurrTemplFmtPps);

  const onReportChange = (update, index) => {
    setOpenReports(
      or => [
        ...or.slice(0, index),
        update,
        ...or.slice(index + 1),
      ]
    );
  }

  const onReportSpawn = (templateIndex) => {
    setOpenReports(or => or.concat(
      {
        id: 4,
        title: "reportNew" + openReports.length,
        template: templateDb[templateIndex],
        extra: {
          notes: "",
          userEmail: "",
          photos: []
        },
        status: "open",
        printted: false
      }
    ));
    /* setSwitchedToNewReportab(false); */
    setTPicker(false);
  }

  return (
    <AppBox>
      <ReportContext.Provider value={{ completion: currCompletion, templFmtPps: currTemplFmtPps }}>
        <AppHeader>
          <AppNav>
            <img alt="LABIGEM" src={logoPng1024x400} />
            <i onClick={menuShow} className="fas fa-bars" />
          </AppNav>
          <AppNav2>
            <TabNew onClick={() => { setOpenTab(-1); }} sel={openTab === -1}><i className="fas fa-list" />{/* Home */}</TabNew>
            <TabNew onClick={() => { setTPicker(true); }} sel={openTab === 0}><i className="fas fa-plus" />{/* New */}</TabNew>
            {
              (openTabs && openTabs.length > 0) &&
              openTabs.map((item, index) =>
                <TabRePro sel={openTab === (index + 1)} onClick={() => setOpenTab(index + 1)}>{openReports[item.openReportIndex].title}<i className="fas fa-times" /></TabRePro>
              )
            }
          </AppNav2>
          {/* <AppNav2>
            <AppNav2Item onClick={() => setOpenTab(1)} sel={openTab === -1}><i className="fas fa-plus" /><p>NEW</p></AppNav2Item>
            <AppNav2Item onClick={() => setOpenTab(2)} sel={openTab === 0}><i className="fas fa-edit" /><p>OPEN</p></AppNav2Item>
            <AppNav2Item sel={openTab === 1}><i className="fas fa-bars" /><p>REPO</p></AppNav2Item>
            <AppNav2Item sel={openTab === 2}><i className="fas fa-ellipsis-h" /><p>MORE</p></AppNav2Item>
          </AppNav2> */}
        </AppHeader>
        {
          openTab === -1 ?
            <RRepo /> :
            <RePro onReportChange={onReportChange} report={openReports[openTabs[openTab - 1].openReportIndex]}
              openReportIndex={openTabs[openTab - 1].openReportIndex/* openTab - 1 */} />
        }
        {menuOpen && <Menu onMenuDismiss={menuDismissed} />}
        {/* <ScaleGrade /> */}
        {/* <ProportionsMapModal /> */}
        {/* <InclusionMapModal /> */}
      </ReportContext.Provider>

      <AppTemplatePicker0 onClick={() => setTPicker(false)} show={tPicker}>
        <AppTemplatePicker >
          <TemplatePicker pickCallback={onReportSpawn} />
        </AppTemplatePicker>
      </AppTemplatePicker0>
    </AppBox>
  );
}

const AppTemplatePicker0 = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000a0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => props.show === true ? "1.0" : "0.0"};
  pointer-events: ${props => props.show === true ? "all" : "none"};
`;

const AppTemplatePicker = styled.div`
  position: absolute;
  border: 1px solid white;
  /* left: 50vw;
  top: 50vh; */
  transform: /* translate(-50%, -50%)  */ scale(0.8);
  overflow: hidden;
  background-color: white;
  width: 90%;
  height: auto;
  box-shadow: 3px 3px 12px #000000;
  border-radius: 50px;
  /* z-index: 999; */
  /* opacity: ${props => props.show === true ? "1.0" : "0.0"};
  pointer-events: ${props => props.show === true ? "all" : "none"}; */
`;

const TabRePro = styled.div`
  position: relative;
  width: calc(180px + 6vw);
  height: calc(90px);
  padding: 0 40px;display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  background-image: linear-gradient(to right, #00000010, #00000010, #00000020);
  /* box-shadow: 2px -4px 4px #00000090; */
  cursor: pointer;
  /* transform: ${props => props.sel ? "scale(1.1)" : "scale(1.0)"}; */
  opacity: ${props => props.sel ? "1.0" : "0.8"};
  transition: all 0.3s;
  color: #000000c0;
  font-size: calc(12px + 0.3vw);
  line-height: calc(1.4 * (12px + 0.3vw));
  font-weight: 500;
  flex-grow: 1;
  i {
    margin: 0 calc(0.2 * (14px + 1.0vw));
    color: #00000090;
    font-size: calc(1.4 * (12px + 0.3vw));
    line-height: calc(1.4 * (12px + 0.3vw));
  }
  .fa-times {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: calc(1.0 * (12px + 0.3vw));
    transform: translate(-75%, -50%);
  }
`;

const TabNew = styled(TabRePro)`
  width: auto;
  flex-grow: 0;
  /* text-transform: uppercase; */
`;

const TabRRepo = styled(TabRePro)`
  text-transform: uppercase;
`;

const AppTabs = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding-top: calc(2.0 * (14px + 1.0vw));
  border-bottom: 2px solid #404040;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  /* background-color: black; */
`;

const AppNav = styled.div`
  position: relative;
  width: 100%;
  height: 140px;
  box-shadow: 3px 3px 6px #000000b0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: black; */
  img {
    height: 100%;
    width: auto;
  }
  i {
    font-size: calc(32px + 1vw);
    color: #30a2ff;
  }
`;

const AppHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  /* background-color: black; */
`;

const AppBox = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  max-width: 1440px;
  overflow: hidden;
  margin: auto;
  box-shadow: 4px 0px 16px #00000040;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  /* background-color: #0F332A;
  background-color: darkgray; */
`;

export default App;
