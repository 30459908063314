import { useState } from "react";
import styled from 'styled-components';
import { F2EditorCompTxt } from 'comps/F2EditorComp';
import { useKey } from "react-use";

const Item2TextGradeSmart = ({ firstChangeCallback, /* gradeName = "Cut Grade", gradeValue = "Excellent" }) */
  gradeName = "Cut", gradeValue, gradeScale = ["Excellent", "Very Good", "Good", "Fair", "Poor"] }) => {
  const [currGrade, setCurrGrade] = useState(gradeValue);
  const [showScale, setShowScale] = useState(false);
  const changeCallback = (e, index) => { firstChangeCallback(); setCurrGrade(e.target.value); e.stopPropagation(); /* setShowScale(false); */ }
  const dismiss = () => setShowScale(false);
  useKey("Escape", dismiss);
  useKey("Enter", dismiss);


  /* const changeCallback = event => setCurrGrade(event.target.value); */
  return (
    <>
      <I2TGBox onClick={() => setShowScale(true)}>
        <p>{gradeName}</p>
        <strong>{currGrade}</strong>
      </I2TGBox>
      {showScale === true &&
        <F2EditorCompTxt gradeName={gradeName} onChange={changeCallback} selectedValue={currGrade} clickOut={dismiss} />
      }
    </>
  );
}

const I2TGBox = styled.div`
  position: relative;
  width: 100%;
  font-family: "Arial", sans-serif;
  font-weight: 400;
  position: relative;
  font-size: calc((18.0 / 922.0) * 92vh);
  strong {font-weight: 600;}
  text-align: start;
  line-height: 1.4;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;



export default Item2TextGradeSmart;
