export const frame2w = 2020;
export const frame2h = 1441;
export const frame2r = frame2h / frame2w;

export const frame3w = 1244;
export const frame3h = 880;
export const frame3r = frame3h / frame3w;

export const gradeTitleColorStr = "#30a2ff";

export const frame2PageWidth = 646;
export const frame2PageHeight = 922;
export const frame2PageHPadding = 53;

export const frame2ScaledPx = "((1.0 / 922.0) * 92.0vh)";
export const frame2ScaledPageHPadding = "calc((53.0 / 922.0) * 92.0vh)";
export const frame2ScaledPageHPadding2 = "calc(50px)";

export const frame2TextHPadding = 7;

export const frame2TitleFont = "Monteserrat";
export const frame2TitleFontSize = 18;
export const frame2TitleFontWeight = "600";

export const frame2GradeFont = "Arial";
export const frame2GradeFontSize = 18;
export const frame2GradeFontWeight = "400";
